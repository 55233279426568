<template>
    <div v-loading="isLoadingChart">
        <div>
            <div class="d-flex align-items-center align-content-center">
                <div class="flex-fill">
                    <!--<el-radio-group v-model="radioTargetView" 
                                    size="small" 
                                    class="mr-3 d-none d-md-block"
                                    @change="getRevenueByMonth">
                        <el-radio-button label="all" class="mb-0">All</el-radio-button>
                        <el-radio-button label="userRegister" class="mb-0">Registered</el-radio-button>
                        <el-radio-button label="userRecharged" class="mb-0">Recharged</el-radio-button>
                        <el-radio-button label="userRechargedMoreThan1" class="mb-0">Recharged > 1 times</el-radio-button>
                        <el-radio-button label="revenue" class="mb-0">Revenue</el-radio-button>
                    </el-radio-group>-->

                    <el-select v-model="radioTargetView" 
                               placeholder="Select" size="small" 
                               class="d-block d-md-none" style="width: 12rem;"
                               @change="getRevenueByMonth">
                        <el-option label="All" value="all" />
                        <el-option label="Registered" value="userRegister" />
                        <el-option label="Recharged" value="userRecharged" />
                        <el-option label="Recharged > 1 times" value="userRechargedMoreThan1" />
                        <el-option label="Revenue" value="revenue" />
                    </el-select>
                </div>
                <div class="d-flex align-items-center">
                    <select class="form-control form-control-sm mr-2"
                            style="width: 8rem;" v-model="numberOfMonth"
                            @change="getRevenueByMonth">
                        <option value="6">Last 6 months</option>
                        <option value="8">Last 8 months</option>
                        <option value="10">Last 10 months</option>
                        <option value="12">Last 12 months</option>
                    </select>
                    <button class="btn btn-sm btn-primary" @click="getRevenueByMonth(true)">
                        <i class="fas fa-sync-alt mr-2"></i> Refresh
                    </button>
                </div>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="revenueByMonth.options"></highcharts>
        <hr/>
    </div>
</template>
<script>
    import chartDataApi from '@/api/common/chartData';
    import Highcharts from 'highcharts';
    import { 
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                isLoadingChart: false,
                radioTargetView: 'all',
                numberOfMonth : 6,
                revenueByMonth: {
                    options: Object.assign(JSON.parse(JSON.stringify(areaSplineChart)), {
                        chart: {
                            height: 550,
                            type: 'areaspline'
                        },
                        yAxis: [],
                        tooltip: {
                            shared: true
                        },
                        plotOptions: {
                            areaspline: {
                                dataLabels: {
                                    enabled: true
                                },
                                fillOpacity: 0.5
                            }
                        },
                    }),
                    tableData: []
                }
            };
        },
        mounted() {
            this.getRevenueByMonth();
        },
        methods: {
            getRevenueByMonth(isForceNew = false) {
                this.isLoadingChart = true;
                chartDataApi.getRevenueByMonth(this.numberOfMonth, isForceNew).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.revenueByMonth.tableData = response.data.data;
                        this.revenueByMonth.options.xAxis.categories = [];
                        this.revenueByMonth.options.xAxis.categories = response.data.data.map(x => x.MonthExport);

                        this.revenueByMonth.options.yAxis = [];
                        this.revenueByMonth.options.series = [];
                        if (this.radioTargetView === "all" || this.radioTargetView === "revenue") {
                            this.revenueByMonth.options.yAxis.push({ // Secondary yAxis
                                title: {
                                    text: 'Amount',
                                    style: {
                                        "font-size": 15,
                                    }
                                },
                                labels: {
                                    format: '${value}',
                                },
                                opposite: true
                            });
                        }

                        if (this.radioTargetView === "all" || this.radioTargetView === "userRegister"
                            || this.radioTargetView === "userRecharged"
                            || this.radioTargetView === "userRechargedMoreThan1") {
                            this.revenueByMonth.options.yAxis.push({ // Primary yAxis
                                labels: {
                                    format: '{value} user(s)',
                                    style: {
                                        color: Highcharts.getOptions().colors[1]
                                    }
                                },
                                title: {
                                    text: 'Number of users',
                                    style: {
                                        color: Highcharts.getOptions().colors[1],
                                        "font-size": 15,
                                    }
                                }
                            });

                        }

                        if (this.radioTargetView === "all" || this.radioTargetView === "userRegister") {
                            this.revenueByMonth.options.series.push({
                                name: "User register",
                                yAxis: (this.radioTargetView === "all") ? 1 : 0,
                                type: 'column',
                                dataLabels: {
                                    enabled: true
                                },
                                tooltip: {
                                    valueSuffix: ' users'
                                },
                                data: response.data.data.map(x => {
                                    return {
                                        y: x.CountUserRegister,
                                    }
                                })
                            });
                        }
                        if (this.radioTargetView === "all" || this.radioTargetView === "userRecharged") {
                            let revenueData = response.data.data.map(x => {
                                return {
                                    y: x.CountUserRecharge
                                }
                            });
                            this.revenueByMonth.options.series.push({
                                name: "User recharged",
                                yAxis: (this.radioTargetView === "all") ? 1 : 0,
                                type: 'column',
                                dataLabels: {
                                    enabled: true
                                },
                                tooltip: {
                                    valueSuffix: ' users'
                                },
                                data: revenueData
                            });
                        }

                        if (this.radioTargetView === "all" || this.radioTargetView === "userRechargedMoreThan1") {
                            let revenueData = response.data.data.map(x => {
                                return {
                                    y: x.CountUserRechargeMoreThan1
                                }
                            });
                            this.revenueByMonth.options.series.push({
                                name: "Recharged more 1 time",
                                yAxis: (this.radioTargetView === "all") ? 1 : 0,
                                type: 'column',
                                dataLabels: {
                                    enabled: true
                                },
                                tooltip: {
                                    valueSuffix: ' users'
                                },
                                data: revenueData
                            });
                        }

                        if (this.radioTargetView === "all" || this.radioTargetView === "revenue") {
                            this.revenueByMonth.options.series.push({
                                name: "Rechaged",
                                type: 'spline',
                                dataLabels: {
                                    enabled: true
                                },
                                tooltip: {
                                    valuePrefix: '$'
                                },
                                data: response.data.data.map(x => {
                                    return {
                                        y: x.TotalRechageAmount,
                                    }
                                })
                            });
                        }
                        if (this.radioTargetView === "all" || this.radioTargetView === "spent") {
                            this.revenueByMonth.options.series.push({
                                name: "Spent",
                                type: 'spline',
                                dataLabels: {
                                    enabled: true
                                },
                                tooltip: {
                                    valueSurfix: this.$pointNameShort
                                },
                                data: response.data.data.map(x => {
                                    return {
                                        y: x.Spent,
                                    }
                                })
                            });
                        }
                        this.isLoadingChart = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart = false;
                });
            },
        }
    }
</script>